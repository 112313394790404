import React, { lazy, useEffect, useState } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";

const Layout = lazy(() => import("./components/Layout/Layout"));
const Home = lazy(() => import("./components/Home/Home"));
const Upload = lazy(() => import("./components/Upload/Upload"));
const Terms = lazy(() => import("./components/Terms/Terms"));
const VideoPlayer = lazy(() => import("./components/VideoPlayer/VideoPlayer"));
const NotFound = lazy(() => import("./components/NotFound/NotFound"));

const App = () => {
  const [clr, setClr] = useState("black");
  const [onHome, setOnHome] = useState(false);

  useEffect(() => console.log(onHome), [onHome]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout color={clr} onHome={onHome} />}>
          <Route
            index={true}
            element={<Home setNavClr={setClr} setOnHome={setOnHome} />}
          />
          <Route
            path="/upload"
            element={<Upload setNavClr={setClr} setOnHome={setOnHome} />}
          />
          <Route
            path="/PrivacyPolicy"
            element={<Terms setNavClr={setClr} setOnHome={setOnHome} />}
          />
          <Route
            path="/video"
            element={<VideoPlayer setNavClr={setClr} setOnHome={setOnHome} />}
          />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
